import request from '@/plugins/request';
export function emailCode (data) {
  return request({
    url: '/endpoint/v1/user/email_code',
    method: 'post',
    data
  });
}
export function register (data) {
  return request({
    url: '/endpoint/v1/user/register',
    method: 'post',
    data
  });
}

export function login (data) {
  return request({
    url: '/endpoint/v1/user/login',
    method: 'post',
    data
  });
}

export function getUser (data) {
  return request({
    url: '/endpoint/v1/user/get',
    method: 'post',
    data
  });
}

export function userUpdate (data) {
  return request({
    url: '/endpoint/v1/user/update',
    method: 'post',
    data
  });
}
export function forgetUser (data) {
  return request({
    url: '/endpoint/v1/user/forget',
    method: 'post',
    data
  });
}


























