import axios from 'axios';
// import { getToken } from "@/utils/auth.js";
// import store from "@/store";
// 创建一个错误
import {Message} from 'element-ui';

function errorLog(msg) {
    Message.error({
        message: msg,
        duration: 3500
    })
}


// 创建一个 axios 实例
const service = axios.create({
    timeout: "500000"
});

// 请求拦截器
service.interceptors.request.use(
    config => {
        config.headers['Access-Control-Allow-Origin'] = '*';
        config.headers['Authorization'] = JSON.parse(localStorage.getItem("access_token"));
        config.headers['Content-type'] = "application/json; charset=utf-8";
        config.data = {
            model: config.data === undefined ? null : config.data,
            header: {
                os: 'pc'
            }
        }
        config.data = JSON.stringify(config.data)
        return config;
    });

// 响应拦截器
service.interceptors.response.use(
    response => {
        // console.log("response=>", response)
        if (response.data.success === true) {
            return response.data.data;
        }
        errorLog(response.data.msg);
        return Promise.reject(response.data);
    },
    error => {
        if (error && error.response) {
            // todo: @ysz 异常码全局封装，此处存在问题
            switch (error.response.code) {
                case 400:
                    error.message = '请求错误';
                    break;
                case 401:
                    error.message = '未授权，请登录';
                    break;
                case 403:
                    error.message = '拒绝访问';
                    break;
                case 404:
                    error.message = `请求地址出错: ${error.response.config.url}`;
                    break;
                case 408:
                    error.message = '请求超时';
                    break;
                case 500:
                    error.message = '服务器内部错误';
                    break;
                case 501:
                    error.message = '服务未实现';
                    break;
                case 502:
                    error.message = '网关错误';
                    break;
                case 503:
                    error.message = '服务不可用';
                    break;
                case 504:
                    error.message = '网关超时';
                    break;
                case 505:
                    error.message = 'HTTP版本不受支持';
                    break;
                default:
                    break;
            }
        }
        errorLog(error.message);
        return Promise.reject(error.message);
    }
);

export default service;
